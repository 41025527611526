

























import { Vue, Component } from 'vue-property-decorator';
import { placeModule } from '@/store';
import { mixins } from 'vue-class-component';
import ListMixins, { InfiniteHandlerState } from '@/components/list/ListMixins';

@Component({
  head: {
    title() {
      return {
        inner: '공지사항'
      }
    }
  }
})
export default class Notice extends mixins(ListMixins) {
  public isNoticeOpen: number = -1;
  public params: any = {
    offset: 1,
    limit: 20
  }

  public $_listMixins_fetch($state: InfiniteHandlerState) {
    placeModule
      .fetchNotice(this.params)
      .then(() => {
        if (this.notice && this.notice.data.length >= this.notice.meta.count) {
          $state.complete();
        } else {
          $state.loaded();
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public createdDate(date) {
    return date.substr(0, 10);
  }

  public toggle(index) {
    if (this.isNoticeOpen === index) {
      this.isNoticeOpen = -1;
    } else {
      this.isNoticeOpen = index;
    }
  }

  public created() {
    this.isLoading = true;
    placeModule.fetchNotice(this.params).finally(() => {
      this.isLoading = false;
    });
  }
  get notice() {
    return placeModule.notice;
  }
  get isApp() {
    if (this.$route.query._from) return true;
    else return false;
  }
}
